import React from 'react'
import Styles from './slider.module.css'
import styles2 from './sliderScss.module.scss';
import SlideShow from '../slideShow'
import "./slider.css";

import PLATFORM from '../../../../assets/images/PLATFORM.png'
import Standard from '../../../../assets/images/Standard.png'
import Effector from '../../../../assets/images/Effector.png'
import Load from '../../../../assets/images/Load.png'
import { Button, Col, Row } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa"
export default function Platform() {


  return (
    <>
      <div className={styles2.bannerPlatform}>

        <div className={`${Styles.sliderContent} ${styles2.sliderContent2}`}>

          <Row gutter={24} className='d-block' >
            <Col xs={24} sm={12} md={12} >
              <h4 >Platform Solution</h4>

              <ul>
                <li><p>MindOx Platform aims to introduce significant enhancements in
                  semiconductor manufacturing efficiency</p>
                </li>
                <li> <p>It achieves a reduction in energy consumption by implementing AI-driven
                  algorithms that dynamically adjust power usage according to real-time
                  demand.
                </p>
                </li>
                <li><p>It reduces space requirements by a noticeable percentage through the
                  integration of extended-reach robots and universal components, enabling a
                  more flexible and efficient factory layout</p>
                </li>
                <li><p>Furthermore, the platform implements AI-based cooling systems to achieve
                  a reduction in water consumption by optimizing water usage according to
                  operational needs, enhancing overall efficiency and sustainability.
                </p> </li>
              </ul>
              <div className={styles2.SalesBtn}>
                <a href='#'><button className="ContactBtn" style={{ marginRight: '15px' }}>Get a demo <FaArrowRight style={{ marginLeft: '5px' ,verticalAlign: 'middle'}} />
                </button></a>
                <Link to="/contact"><button className="SalesBtn SalesBtn2">Contact sales</button></Link>
              </div>

            </Col>
            <Col xs={24} sm={12} md={12} >
              <LazyLoadImage src={PLATFORM} alt="ce" />
            </Col>

          </Row>


        </div>
      </div>
      <div className={styles2.bannerPlatform} style={{ backgroundColor: 'transparent' }}>

        <div className={`${Styles.sliderContent} ${styles2.sliderContent2}`}>

          <Row gutter={24} className='d-block' >
            <Col xs={24} sm={12} md={12} >
              <h4 >Standard & Customized EFEM</h4>


              <ul>
                <li> <p>The modular EFEM is a fully configurable automation system for a wide range of
                  semiconductor substrate processing applications.</p>
                </li>
                <li> <p>We offer customers the ability to match the automation requirements of the process by
                  customizing our EFEM or cascading multiple EFEM tools to optimize their output
                </p></li>
                <li> <p>The product comprises options with variable port configuration capability, from single to
                  beyond four load ports in every automation configuration.</p>
                </li>
                <li> <p>We also offer our customers the ability to integrate and streamline MindOx Techno pre- and
                  post-processing equipment with the parent tool or the main host of the fabrication floor.

                </p></li>
              </ul>
              <div className={styles2.SalesBtn}>
                <a href='https://www.mindoxtechno.com/product/efem'><button className="ContactBtn" style={{ marginRight: '15px' }}>See products <FaArrowRight style={{ marginLeft: '5px' ,verticalAlign: 'middle'}} />
                </button></a>
              </div>

            </Col>
            <Col xs={24} sm={12} md={12} >
              <LazyLoadImage src={Standard} alt="ce" />
            </Col>

          </Row>


        </div>
      </div>
      <div className={styles2.bannerPlatform} style={{ backgroundColor: 'transparent' }}>

        <div className={`${Styles.sliderContent} ${styles2.sliderContent2}`}>

          <Row gutter={24} className='d-block' >
            <Col xs={24} sm={12} md={10} >
              <LazyLoadImage src={Load} alt="ce" />
            </Col>
            <Col xs={24} sm={12} md={14} >
              <h4 >Load ports</h4>



              <ul>
                <li>   <p>Our range of Load ports is designed to offer easy and seamless upgradability to
                  meet the industry's current demands, providing versatile solutions for a variety of
                  applications.</p></li>
                <li>   <p>MindOx’s load port addresses the industry's current requirement for different load
                  ports for various applications by offering a single, adaptable solution
                </p></li>
                <li> <p>It is designed to handle 300mm FOUPs, tape frames, panels, 150mm, and 200mm
                  open cassettes with minimal adjustments, aiming to reduce complexity and
                  enhance efficiency.</p>
                </li>
                <li><p>Advanced features such as built-in wafer mapping, thickness monitoring, and many
                  other AI features for seamless substrate variant changeovers are available to
                  optimize workflow, making it a versatile choice for diverse manufacturing needs

                </p></li>
              </ul>
              <div className={styles2.SalesBtn}>
                <a href='https://www.mindoxtechno.com/product/accessories#LPdiv'><button className="ContactBtn" style={{ marginRight: '15px' }}>See products <FaArrowRight style={{ marginLeft: '5px' ,verticalAlign: 'middle'}} />
                </button></a>
              </div>

            </Col>

          </Row>


        </div>
      </div>

      <div className={styles2.bannerPlatform} style={{ backgroundColor: 'transparent' }}>

        <div className={`${Styles.sliderContent} ${styles2.sliderContent2}`}>

          <Row gutter={24} className='d-block' >
            <Col xs={24} sm={12} md={12} >

              <h4 >End Effector</h4>
              <ul>
                <li>
                  <p>MindOx end effectors offer flexible configuration options, capable of handling a wide range of substrate sizes, including 150mm, 200mm, 300mm, frames, and panels.</p></li>
                <li> <p>Available in various designs such as paddle, horseshoe, and more, they are engineered to support substrate warpage beyond current industry standards.

                </p></li>
                <li>  <p>Each configuration is customizable with options like vacuum, friction pads, and edge grip technology to ensure minimal defects and high placement accuracy.
                </p></li>
                <li><p>Our end effectors are constructed from a variety of materials, including ceramics, metals, and polymersto withstand temperatures from low levels up to 1100°C.</p></li>
                <li><p>For tailored solutions designed to meet your specific requirements, contact us today for more information.</p></li>
              </ul>




              <div className={styles2.SalesBtn}>
                <a href='https://www.mindoxtechno.com/product/accessories#EEdiv'><button className="ContactBtn" style={{ marginRight: '15px' }}>See products <FaArrowRight style={{ marginLeft: '5px' ,verticalAlign: 'middle'}} />
                </button></a>
              </div>

            </Col>
            <Col xs={24} sm={12} md={12} >
              <LazyLoadImage src={Effector} alt="ce" />
            </Col>

          </Row>


        </div>
      </div>

      <div className={styles2.bannerPlatform2} style={{ backgroundColor: 'transparent' }}>

        <Row gutter={24} className='d-block' >
          <Col xs={24} sm={24} md={24} >

            <h4>Our Advanced Semiconductor Solutions</h4>

            <ul>
              <li> <p><b>Our Advanced Semiconductor Solutions</b> seamlessly combine product integration and engineering resources to enhance your
                manufacturing capabilitie.</p>
              </li>
              <li>    <p>Our comprehensive approach ensures that cutting-edge automation, robust control systems, and precise software solutions are expertly
                managed and supported through every stage of the product lifecycle.</p></li>
            </ul>



            <div className={styles2.SalesBtn}>
              <button className="ContactBtn" style={{ marginRight: '15px' }}>Engineering Hub Services </button>
              <button className="ContactBtn" style={{ marginRight: '15px' }}>Semiconductor Hub Services
              </button>

            </div>

          </Col>
        </Row>
      </div>
    </>
  )
}
