import React from 'react'
import Styles from './slider.module.css'
import styles2 from './sliderScss.module.scss';
import SlideShow from '../slideShow'
import "./slider.css";

import SemiCon from '../../../../assets/images/SemiCon.png'
import AutomationImg from '../../../../assets/images/Automation.png'
import Standard from '../../../../assets/images/Standard.png'
import Load from '../../../../assets/images/Load.png'
import { Button, Col, Row } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa"

export default function Automation() {


  return (
    <>
      <div className={styles2.bannerPlatform}>

        <div className={`${Styles.sliderContent} ${styles2.sliderContent2}`}>

          <Row gutter={24} className='d-block' >
            <Col xs={24} sm={12} md={14} >
              <h4 >Automation Solution</h4>

              <ul>
                <li><p>MindOx Platform offers customized software solutions for smarter
                  semiconductor manufacturing</p>
                </li>
              </ul>

              <p><b>1.Mighty Software Platform Solutions</b></p>
              <p>MindOx Equipment Software Solutions specialize in SEMI-compliant desktop and
                web applications, including the MightyTalk EFEM system for precise wafer handling,
                optimized productivity, and seamless semiconductor equipment control.</p>
              <p><b>2. Semiconductor Equipment Software Solution As a Service</b></p>
              <p>We develop your SEMI-compliant equipment software needs, including desktop and
                web solutions with metrology integration.</p>
              <p><b>3. SEMI Standard Communication</b></p>
              <p>MindOx SECS/GEM is a specialized software solution that ensures seamless
                communication between semiconductor equipment and factory host systems,
                leveraging industry-standard SECS/GEM and EDA protocols for reliable equipment-to-host connectivity</p>

              <div className={styles2.SalesBtn}>
                <button className="ContactBtn" style={{ marginRight: '15px' }}>Get a demo <FaArrowRight style={{ marginLeft: '5px' ,verticalAlign: 'middle'}} />
                </button>
                <Link to="/contact">  <button className="SalesBtn SalesBtn2" > Contact sales</button></Link>
              </div>

            </Col>
            <Col xs={24} sm={12} md={10} >
              <LazyLoadImage src={AutomationImg} alt="ce" />
            </Col>

          </Row>


        </div>
      </div>

      <div className={styles2.bannerPlatform} style={{ backgroundColor: 'transparent' }}>

        <div className={`${Styles.sliderContent} ${styles2.sliderContent3}`}>

          <Row gutter={24} className='d-block' >
            <Col xs={24} sm={12} md={8} >
              <LazyLoadImage src={SemiCon} alt="ce" />
            </Col>
            <Col xs={24} sm={12} md={14} >
              <h4 >Key features</h4>

              <div className='features'>
                <p>SECS/GEM Compliance</p>
                <p>Real-time Data Collection</p>
                <p>Event Reporting and Alarms</p>
                <p>Remote Control</p>
                <p>Data Logging and Traceability</p>
                <p>Customization and Integration</p>
                <p>Scalability</p>
              </div>

              <ul>
                <li>   <p>MindOx SECS/GEM is crucial for modern semiconductor manufacturing, ensuring precision
                  and seamless communication between equipment and systems.

                </p></li>
                <li> <p>It supports wafer processing, assembly, and testing, integrating equipment with factory
                  automation for optimized performance.</p>
                </li>
                <li><p>With MindOx SECS/GEM, manufacturers achieve higher automation, improved yield,
                  reduced downtime, and industry compliance.

                </p></li>
              </ul>


            </Col>

          </Row>


        </div>
      </div>


    </>
  )
}
